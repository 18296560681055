import React, { Component } from 'react';
import Particles from 'react-particles-js';
import Countdown from "react-countdown-now";
import ReactMapGL, { Marker } from 'react-map-gl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import { User } from './models';

import './assets/scss/reset.scss'
import styles from './assets/scss/screen.module.scss';

toast.configure({
  style: {fontSize: '16px'}
});

const Header = () => {

  return (
    <>
      <div className={styles.header}>
        <nav>
          <strong className={styles.red}>
            const&nbsp;
          </strong> 
          <strong className={styles.white}>
            menu = (
          </strong>
          <a href="#mission">
            the_mission
          </a>
          , 
          <a href="#schedule">
            schedule
          </a>
          , 
          <a href="#signup">
            sign_up
          </a>
          <strong className={styles.white}>
            );
          </strong> 
        </nav>
        <div className={styles.date}>
          <strong className={styles.white}>// 15-16 february 2020</strong>&nbsp;-&nbsp;
          <Countdown date={1581760800 * 1000} />
        </div>
        <h1 className={styles.hackathonTitle}>Bluecherry Hackathon</h1>
        <p className={styles.info}>
          Bulbs, coffeemachines, freezers, ... things that deliver a useful dataset slowly become part of 
          <strong className={styles.white}> the Internet of Things.</strong>
          <br /> 
          ...
          <br /> 
          <strong className={styles.white}>error(<strong className={styles.red}>no_connectivity</strong>);</strong><br />
          ...<br /> 
          Our radars detected piles of <strong className={styles.red}>dusty hardware in the BlueCherry headquarters in Knokke-Heist...</strong> 
          You and your friends are challenged to <strong className={styles.white}>participate in our first edition of the 24h Bluecherry Hackathon!</strong> 
        </p>
        <section className={styles.cta}>
          <div className={styles.btn}>
            <a href="#mission">read_more</a>
          </div>
          <div className={`${styles.btn} ${styles.red}`}>
            <a href="#signup">resolve(sign_up)</a>
          </div>
        </section>
      </div>
    </>
  )
} 

class CoderField extends Component {
  onChange = ev => {
    const { value } = ev.target;
    const paramToOverwrite = ev.target.getAttribute('bind');
    this.props.user[paramToOverwrite] = value;
    this.props.updateUsers();
  };

  render() {
    const { number, user } = this.props;
    const { name, email, school} = user;

    return (
      <div className={styles.input_field}>
        <label className={styles.input__title}>Coder {number}</label>
        <div className={styles.input__wrapper}>

          <div>
            <p className={styles.input}>
              <label htmlFor={`name${number}`}>name</label>
              <input type="name" name={`name${number}`} id={`name${number}`} onChange={this.onChange} bind="name" value={name}/>
            </p>
          </div>

          <div>
            <p className={styles.input}>
              <label htmlFor={`email${number}`}>email</label>
              <input type="email" name={`email${number}`} id={`email${number}`} onChange={this.onChange} bind="email" value={email}/>
            </p>
          </div>

          <div>
            <p className={styles.input}>
              <label htmlFor={`degree${number}`}>school</label>
              <input type="text" name={`degree${number}`} id={`degree${number}`} onChange={this.onChange} bind="school" value={school}/>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default class App extends Component {

  constructor() {
    super();

    this.state = {
      teamName: '',
      users: [
        new User(),
        new User(),
        new User(),
        new User(),
      ],
      state: 'normal',
      viewport: {
        width: 400,
        height: 400,
        latitude: 50.9043076,
        longitude: 3.4932118,
        zoom: 7
      }
    };
  }

  updateTeamName = ev => {
    this.setState({ teamName: ev.target.value });
  };

  showToast = () => {
    toast.success("Registration succeeded", {
      position: toast.POSITION.TOP_CENTER
    });
  }

  validateForm = () => {
    const { teamName, users } = this.state;
    const [ user ] = users;
    let errors = false

    console.log(teamName);
    if (teamName === '') {
      errors = true;
      toast.error('Please enter a valid teamname.', {
        position: toast.POSITION.TOP_CENTER
      });
    }

    if (user.name === '') {
      errors = true;
      toast.error('Please enter the name of the first coder', {
        position: toast.POSITION.TOP_CENTER
      });
    }

    if (user.email === '') {
      errors = true;
      toast.error('Please enter the email of the first coder', {
        position: toast.POSITION.TOP_CENTER
      });
    }

    if (user.email === '') {
      errors = true;
      toast.error('Please enter the school of the first coder', {
        position: toast.POSITION.TOP_CENTER
      });
    }

    return errors;
  };

  generateObj = () => {
    const { teamName, users } = this.state;
    const obj = {
      teamname: teamName,
    };

    users.forEach((user, index) => {
      obj[`member${index+1}_school`] = user.school;
      obj[`member${index+1}_name`] = user.name || '';
      obj[`member${index+1}_email`] = user.email;
    })

    return obj;
  };

  submitForm = () => {
    if (!this.validateForm()) {
      this.setState({ state: 'processing' })

       fetch('registration.php', {
         method: 'POST',
         body: JSON.stringify(this.generateObj()),
         headers: {
           Accept: 'application/json, text/plain, */*',
           'Content-Type': 'application/json',
         },
       }).then(({ status }) => {

         if (status !== 200) {
           throw new Error();
         }

         this.setState({
           state: 'successfull',
           teamName: '',
           users: [
             new User(),
             new User(),
             new User(),
             new User(),
           ]
         })

         toast.success("Registration succeeded", {
           position: toast.POSITION.TOP_CENTER
         });

       }).catch(() => {
         toast.error('Something went wrong with the registration form. Please send a mail to info@bluecherryhackathon.be', {
           position: toast.POSITION.TOP_CENTER
         });
       })
    }
  };

  updateUsers = () => {
    this.forceUpdate();
  };
  
  render() {
    const { teamName, users, state } = this.state;

    // eslint-disable-next-line no-restricted-globals
    const amountOfParticles = screen.width > 800 ? 60 : 20;

    const particlesConfiguration = {
      particles: {
        number: {
          value: amountOfParticles,
        },
        size: {
          value: 3
        },
        move: {
          speed: 1.5,
          attract: {
            rotateX: 600,
            rotateY: 1200,
          }
        }
      },
      interactivity: {
        events: {
          onhover: {
            enable: true,
            mode: 'repulse'
          }
        }
      }
    }

    return (
      <>
        <Particles params={particlesConfiguration} className={styles.particles} />
        <div className={styles.App}>
          <Header />
          <main>
            <article className={styles.mission} id="mission">
              <h1 className={styles.title}>the_mission();</h1>
              <div className="grid">
                <p className={styles.text}>
                  Your team gets exactly <span className={styles.whiteText}>24h</span> to create a new and <span className={styles.redText}>smart IoT solution from scratch</span>. You design a prototype in hardware and code a <span className={styles.whiteText}>Node.js</span> server together with a nice <span className={styles.whiteText}>user interface</span>. Every team has the same <span className={styles.redText}>tools and parts</span> at their disposal.  For the duration of the event you can ask for tips and tricks from the <span className={styles.redText}>BlueCherry code gurus</span>. Every coach has <span className={styles.whiteText}>years of experience</span> in the IoT field.
                </p>

                <ul className={styles.tools}>
                  <lh lassName={styles.tools__title}>predisposed_tools</lh>
                  <li>- an Ubuntu server with Node.js preinstalled and a public IPv4</li>
                  <li>- a Wi-Fi microcontroller (ESP32) with multiple sensors and actuators</li>
                  <li>- a good internet connection</li>
                  <li>- tasty snacks’n soda</li>
                </ul>
              </div>
            </article>

            <article id="prices">
              <h1 className={styles.title}>whats_in_it_for_you();</h1>
              <section className={styles.prices}>
                <article className={styles.price}>
                  <h2 className={styles.teamName}>
                    Team 0
                  </h2>

                  <div className={styles.priceDetails}>
                    <strong className={styles.codeBlock}>{'<'}</strong>
                    <span className={styles.redText}>€500</span>
                    <strong className={styles.codeBlock}>{'/>'}</strong>
                  </div>
                </article>
               <article className={styles.price}>
                  <h2 className={styles.teamName}>
                    Team 1
                  </h2>

                  <div className={styles.priceDetails}>
                    <strong className={styles.codeBlock}>{'<'}</strong>
                    <span className={styles.redText}>€320</span>
                    <strong className={styles.codeBlock}>{'/>'}</strong>
                  </div>
                </article> 
               <article className={styles.price}>
                  <h2 className={styles.teamName}>
                    Team 2
                  </h2>

                  <div className={styles.priceDetails}>
                    <strong className={styles.codeBlock}>{'<'}</strong>
                    <span className={styles.redText}>€180</span>
                    <strong className={styles.codeBlock}>{'/>'}</strong>
                  </div>
                </article> 
              </section>
            </article>

            <article id="schedule">
              <h1 className={styles.title}>schedule();</h1>
              <p className={styles.redText}>// schedule 15/02-16/02</p>
              <p className={styles.text}>// 9.00 - introduction & preps</p>
              <p className={styles.text}>// 10.00 - start 24h hackathon</p>
              <p className={styles.text}>// —> stick your heads together</p>
              <p className={styles.text}>// —> code, code, code 🤓</p>
              <p className={styles.text}>// 10.00 - end 24h hackathon</p>
              <p className={styles.text}>// reveal of the winning team</p>
            </article>

            <article className={styles.signup} id="signup">
              <h1 className={styles.title}>sign_up();</h1>
              <p className={styles.text}>
                Better be quick! Only 10 teams will compete in this unique 24h experience. 
                <br />
                <span className={styles.redText}>*signing up is possible until 1577836799 (unix_timestamp);</span>
              </p>
              <p className={styles.text}>
                During the event your team has 
                <span className={styles.whiteText}>&nbsp;BlueCherry code gurus available </span>
                to get advice from;
              </p>
              <div className={styles.signup__info}>
                <form className={styles.form}>

                  <fieldset>
                    <legend>
                      <div className={styles.dots}>
                        <div className={styles.redDot} />
                        <div className={styles.orangeDot} />
                        <div className={styles.greenDot} />
                      </div>
<span className={styles.info}>bluecherry commit -m “team name &amp; coders”</span>
                    </legend>

                    <div className={styles.input_field}>
                      <div className={styles.input__wrapper}>
                        <div></div>
                        <div className={styles.input}>
                          <label className={styles.input__title}>{'${team.name}'}</label>
                          <input type="name" name="name" id="name" value={teamName} onChange={this.updateTeamName} />
                        </div> 
                        <div></div>
                      </div>
                    </div>

                    {
                      users.map((user, index) => (
                        <CoderField number={index} user={user} updateUsers={this.updateUsers}/>
                      ))
                    }
                  </fieldset>

                  <div className={styles.submit}>
                    {
                      state === 'normal' && (
                        <div className={`${styles.btn} ${styles.red}`} onClick={this.submitForm}>
                          EXEC -IT "Enter the game"
                        </div>
                      )
                    }

                    {
                      state === 'processing' && (
                        <div className={`${styles.btn} ${styles.green}`} onClick={this.submitForm}>
                          $ tar xvzf file.tar.gz
                        </div>
                      )
                    }

                    {
                      state === 'successfull' && (
                        <div className={`${styles.btn} ${styles.purple}`} onClick={this.submitForm}>
                          Registration successfull, you will receive an confirmation email. See you soon wizards.
                        </div>
                      )
                    }

                  </div>
                </form>
              </div>
            </article>
            <article id="contact">
              <h1 className={styles.title}>contact();</h1>
              <p className={styles.whiteText}>DPTechnics bvba</p>
              <p className={styles.redText}>Westkapellestraat 396/44</p>
              <p>8300 Knokke-Heist</p>
              <p className={styles.whiteText}>Belgium</p>

              <p>Tel: <a href="tel:003250621379">+32 50 62 13 79</a></p>
              <p>Email: <a href="mailto:info@bluecherryhackathon.be">info@bluecherryhackathon.be</a></p>

              <ReactMapGL
                {...this.state.viewport}
                onViewportChange={(viewport) => this.setState({viewport})}
                mapboxApiAccessToken="pk.eyJ1IjoiZHBhcGUiLCJhIjoiY2lqbmM2Zmo2MDA0dXZ0bTdlaWo5ZW5lZCJ9.JphJVy7bx_2rLgILPGsEUQ"
                width="100%"
                mapStyle='mapbox://styles/dpape/ck3imj9731kpa1coah295l0bk'
              >

                <Marker latitude={51.323682} longitude={3.274307} offsetLeft={-20} offsetTop={-10}>
                  <div className={styles.marker}></div>
                </Marker>
              </ReactMapGL>

            </article>
          </main>

        </div>
      </>

    )
  }
}
